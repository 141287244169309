/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year: 2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/
/**
 * 【发布设置】
 */
// 环境模式（生产环境》production；开发环境》development；）
const isReleased = (process.env.NODE_ENV === 'production');
const isOrigin = true; //原始域名（前端与API为同源站点）
//终端信息
const appPlatform = 'admin';
const appDevice = 'pc';
const appVersion = '1.0.0';
/**
 * 【API设置】
 */
//【数据模块】
const dataModule = 'admin';
//【正式地址】
const NormalBaseUrl = 'https://oa.hdshangke.com';
const NormalWsUrl = 'ws://oa.codeavatar.vip:9110';
const NormalSkey = 'MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDHQIBV8TtoscNz6aQOej8HnDC3Nn20HkCYs3B2aI1E2wA0rBkRomxBnvhpFZkrxliLmjv6ALxkPSfrRGsWy/6jKsTCgKBURunUyy2qgBK3mu0OkMVSR++Z5J1Lf3Y/MgJRZMCuEww0JZ6AzqyhhUaEcS4xfGufx3Z60NtvNLKo2j7zlSusiYlBBwdn4FLmcnIkWdEzsM0j4SJ8dUKpcm0dPQ4p7IW63Tjqtb1JREnZm287FPppobZg6mYijO3/a7doKh4DIFA++kEPC9kYVjFyU3RU8tX9WPXGwHEBTfH1o0dMxtvQWQJc6Q8SggGFfx9eTNhrLag/NGbS1W7bphXZAgMBAAECggEACoQyAdc4nn77ZOvI9+al2v34aEMdAVuzZxvZbLZ59k9kxGLs88xR0GjWjZ1RN0lBtSSP09MkHlpH+BflWw+rQeto9Scazzfq8YtDvsMBueUYa/NiJsSbNdEmKCzzDcTS8H7T2JwywbHWms/Ntry1Cxp6KKx7dbRuYW8Sldz/LQA4B6p6HuoUCzVqbMq2M7M3ysxdg7hX0YDrnHjtzGzJbr3pFTxxC6BVH2Jk5DM6ZYZLJV+SNrf0XTY0b68plE3Q7Q5Sn8rS7si8sUe4zVkQYzqc7TU2VC7ozH4Y32pqDRlEDWhx5j99qN1L17+vGMq/2ep+NyawtR3Q2hKrVBZOMQKBgQDmzpJY+OwV/bTKbXTuF16yHGePfZzx+hta8YafZUJiSvt+067kFWSvbGAAOeJb8fP6vNMApMOutAtpDC6VNLWVlAzW0w16N92bAxN03r3++RpSO3qOzSYy+kJz02sOYwARYnnSdVN/Fz/JHsN4ZNRBbp5bJCxGE+ZadkE6Gp1H9QKBgQDdADDMh6NyuUJHeORmewt/pCtefqedJ39jOvYqrZu8s6omFYRwIPccy0RTZ3IE8LTNG4yuoKn+tTEqY70PVu5J4JqGwPOfzr2oosWKaKy3q2bcGXWl5D8fNRNjRly/o9u+MQR78gv1XnUxJuuLiOJ5h5kqMM83HBgY85gsQsz71QKBgHEKx36wG+EBa2Shi115MLYHV2ld2lphZrh6BrdWvtapZtfNEaFAIRoR+ErkDst9i0NI7Acqq3SLVfNTNhV5xV3HLyqgH7+2UOE09niNB30zwAJ6gyIhtA3PqHBWId7f+g6JrqS3NffPCTyVxqLWr2aEqSZ2ZwDDilTQCk5M7q9pAoGADlrx0IdK/au8+2mF5IY/k/X0/zlY5+Nl6estjE4knJhLCBhDtwjdJFirwoMoq9t60wvkEn/LKwlX/TnnUPTDm7bkMrab3JiQR0Dzde8gsPmIP+nDc5gk5YEMFlm+spAROQR0Fxz8295b3Gp7yF+G3oAJWe07uIexpQVFsDJSoFUCgYA3DLeugxcUG9MOk8D+zxDSLS9710gF++S+7pLdqyiRbS3XSkJENlSrBVCR319idzCrbUbKfnvVnfXah/p8k9bJhHf5LKjnMpxXUQwWXjQVUEcpMWn5s0iFX76jpR4d0VggCb6/CCiBAZCxxNTjXPCdoFaVixrk8C8PimTPgMl+7Q==';
//【测试地址】
const TestBaseUrl = 'http://ntoa.tool.com';
const TestWsUrl = 'ws://ntoa.tool.com:9110';
const TestSkey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx0CAVfE7aLHDc+mkDno/B5wwtzZ9tB5AmLNwdmiNRNsANKwZEaJsQZ74aRWZK8ZYi5o7+gC8ZD0n60RrFsv+oyrEwoCgVEbp1MstqoASt5rtDpDFUkfvmeSdS392PzICUWTArhMMNCWegM6soYVGhHEuMXxrn8d2etDbbzSyqNo+85UrrImJQQcHZ+BS5nJyJFnRM7DNI+EifHVCqXJtHT0OKeyFut046rW9SURJ2ZtvOxT6aaG2YOpmIozt/2u3aCoeAyBQPvpBDwvZGFYxclN0VPLV/Vj1xsBxAU3x9aNHTMbb0FkCXOkPEoIBhX8fXkzYay2oPzRm0tVu26YV2QIDAQAB,,';

let BASE_API_URL = (isReleased?NormalBaseUrl:TestBaseUrl);
let BASE_WS_URL = (isReleased?NormalWsUrl:TestWsUrl);
let BASE_SKEY = (isReleased?NormalSkey:TestSkey);

/**
 * 打包检测 (VUE3)
 */
if(process.env.NODE_ENV === 'production'){
    if(isOrigin){
        BASE_API_URL = `${location.origin}`;
        BASE_WS_URL = `ws://${location.origin}:9110`;
    }
}

export default {
    //核心控制
    released:isReleased,
    debug:!isReleased,
    baseurl:BASE_API_URL,
    basews:BASE_WS_URL,
    baseskey:BASE_SKEY,
    //测试
    test:{
        isOpen:false,
        catoken:'4c1df9784841d68a434a3c74550c0e9b3ab8b015e15026466a69da7dce3ba21760d5c2c3a98ceca9dbe9cf443cde8efe680280260e1c521d62f0808c9c7151bc87818783ed78546e57854d99fe776f966b4b7946bbc6c5316e75068584dd9fea6525030a7a4809e82917e6a54ae9108a60415bfab141dcc2ba0454e52a9e75c79d606722f236dbc7d1d35df28ef42de412482f53cdd4d66a18eb5452f53a9f76',
    },
    //固定参数
    getPageSize:()=>{
        return 10;
    },
    //应用信息
    app:{
        platform:appPlatform,
        device:appDevice,
        version:appVersion,
        dataModule,
    },
}