/**
 * +----------------------------------------------------------------------
 * | @Author: codeavatar   @Year：2023
 * +----------------------------------------------------------------------
 * | @Email: codeavatar@aliyun.com
 * +----------------------------------------------------------------------
 **/

//系统信息
const appInfo={
    allName:'邯郸市尚科OA系统',
    simpleName:'尚科OA',
};

export default {
    app:appInfo,
};